
.proceso{
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: flex-start;
    width: 100%;
    height: 5vh;
    margin-left: 3%;
    margin-top: 1%;
    padding: 0;
    font-size: 1rem;
    font-weight: 500;
    background-color:beige;
}

.pedido{
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;
    width: 100vw;
    height: 10vh;
    margin: 2vh 0;
    font-weight: 700;
    font-size: 1.3rem;
}

.titulowrap{
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;
    width: 83%;
    height: 5vh;
    padding-left: 12%;
    font-size: 1rem;
    font-weight: 700;
}

.pedidowrap{
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;
    width: 83%;
    height: 5vh;
    padding-left: 12%;
    font-size: 1rem;
    font-weight: 400;
}
        .cantidad{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 6%;
            margin-left: 1.5%;
        }
        .platillo{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 23%;
        }
        .notas{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 22%;
        }
        .reparto{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 16%;
        }
        
        .boton{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 12vw;
            margin-left: 5%;
            border-radius: 10px;
            background-color: white;
            color: black;
        }
        .boton:hover{
            background-color: black;
            color: white;
        }
        .enviowrap{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width:31%;
        }
            .reparto_e{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                width: 50%;
            }
            .boton_e{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                width: 30%;
                margin-left: 17%;
                border-radius: 10px;
                background-color: white;
                color: black;
            }
            .boton_e:hover{
                background-color: black;
                color: white;
            }
            