:root{
    /* Colores */
    --orange: #F7931A;
    --soft-orange: #FFE9D5;
    --warm-black: #201E1C;
    --warm-black1: #282623;
    --off-white:  #FAF8F7;
    --just-white: #FFFFFF;
    --grey: #bababa;
    --green: #103606;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html{
    font-size: 62.5%;
}
.all{
    background-color: var(--just-white);
    color: #103606;
}
    .header_title{
        font-size: 5.5rem;
        font-weight: 800;
        height: 15%;
        margin: 1.5vh 0vh;
        
    }
    .header_subtitle{
        font-size: 5.5rem;
        font-weight: 700;
    }

    .grid_header{
        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content: center;
        width: 100%;
        height: 6vh;
        padding-left: 12%;
        margin-bottom: -1%;
        font-size: 2.3rem;
        font-weight: 700;
        background-color:beige;
    }
        .cantidad{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 10%;
            margin-left: 1%;
        }
        .platillo{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 25vw;
        }
        .notas{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 22%;
        }
        .reparto{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 16%;
        }
        
        .boton_proceso{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 9%;
            height: 6vh;
            margin-left: 2%;
            border-radius: 10px;
            font-size: 2rem;
            font-weight: 700;
            background-color: white;
            color: black;
        }
        .boton_proceso:hover{
            background-color: black;
            color: white;
        }
    .title_proceso{
        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content: flex-start;
        width: 100%;
        height: 5vh;
        margin-left: 0;
        margin-top: 1%;
        padding-left: 3%;
        font-size: 2rem;
        font-weight: 700; 
        width: 100%;
        background-color:beige;
    }

    .borrar{
        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content: center;
        width: 100%;
        height: 5vh;
        padding-left: 12%;
        font-size: 2.3rem;
        font-weight: 700;
        background-color: var(--orange);
    }
    .pedido_proceso_section{
        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content: center;
        width: 100%;
        height: 8.5vh;
        margin: 0;
        padding-left: 12%;
        font-size: 1.7rem;
        font-weight: 700;
        background-color: var(--just-white);
        box-shadow: 0px 15px 6px gainsboro;
        background-color:var(--orange) ;
    }
            .enviowrap{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                width:10%;
            }
                .reparto_e{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items:center;
                    width: 50%;
                }
                .boton_e{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items:center;
                    font-size: 2rem;
                    font-weight: 700;
                    width: 100%;
                    height: 6vh;
                    margin-left: 17%;
                    border-radius: 10px;
                    background-color: white;
                    color: black;
                }
                .boton_e:hover{
                    background-color: black;
                    color: white;
                }
            
/* @media only screen and (min-width: 1000px) {
 
        .platillo{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 23%;
        }
        .notas{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 22%;
        }
        .reparto{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 16%;
        }
        
        
        .boton_e:hover{
            background-color: black;
            color: white;
        }
            .reparto_e{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                width: 50%;
            }
            

} */