:root{
    /* Colores */
    --orange: #F7931A;
    --soft-orange: #FFE9D5;
    --warm-orange: #dfa48d;
    --warm-black: #201E1C;
    --warm-black1: #282623;
    --off-white:  #FAF8F7;
    --just-white: #FFFFFF;
    --grey: #bababa;
    --green: #103606;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html{
    font-size: 62.5%;
}
.all{
    background-color: var(--just-white);
    color: #103606;
}
    .header_title{
        font-size: 3rem;
        font-weight: 800;
        height: 15%;
        margin: 1.5vh 0vh;
        
    }
    .header_subtitle{
        font-size: 3rem;
        font-weight: 700;
    }

    .title_proceso{
        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content: flex-start;
        width: 100%;
        height: 5vh;
        margin-left: 3%;
        margin-top: 1%;
        padding: 0;
        font-size: 2.5rem;
        font-weight: 700; 
    }
    
    .pedido_proceso_section{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        padding-left: 1%;
        font-size: 2.2rem;
        font-weight: 600;
        margin-bottom: 2vh;
        background-color: var(--soft-orange);
        box-shadow: 0px 15px 6px gainsboro;
    }
    .proceso_sub{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 12vh;
    }
        .proceso_sub_left{
            display: flex;
            flex-wrap: wrap;
            width: 25%;
            height: 12vh;
        }
            .id_title{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                margin-left: 25%;
                width: 25%;
                height: 6vh;
                font-weight: 800;
            }
            .id{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: 25%;
                height: 6vh;
            }
            .cantidad_title{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 3vh;
                font-weight: 800;
            }
            .cantidad{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 3vh;
            }
              
        .proceso_sub_right{
            display: flex;
            flex-wrap: wrap;
            width: 75%;
            height: 12vh;
            margin:0;
        }
            .platillo_title{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;
                height: 3vh;
                font-weight: 800;
            }
            .platillo{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                width: 100%;
                height: 3vh;
            }
    
            .notas_title{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:flex-start;
                width: 100%;
                font-weight: 800;
                height: 3vh;
            }
            .notas{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:flex-start;
                width: 100%;
                height: 3vh;
                margin: 0;
                padding: 0;
            }

    .reparto{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 100%;
            height: auto;
            margin-top: 1vh;
    }
        
        .boton_mobile{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 100%;
            height: 6vh;
            margin-left:-1vw;
            border: 0;
            font-size: 2.8rem;
            font-weight: 700;
            background-color: white;
            color: black;
        }
        .boton_mobile:hover{
            background-color: black;
            color: white;
        }
        .reparto_e{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 100%;
            height: 5vh;
            color: var(--warm-orange);
        }
            .enviowrap{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                width:31%;
            }
                
                /* .boton_e{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items:center;
                    width: 30%;
                    margin-left: 17%;
                    border-radius: 10px;
                    background-color: white;
                    color: black;
                }
                .boton_e:hover{
                    background-color: black;
                    color: white;
                } */
            
