form{
    width: 100vw;
    height: 100%;
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; 
}
h1{
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    justify-content: center;
    width:100%;
    height: 6vh;
    font-size: 1.3rem;
    margin: 2%;
}
input{
    width:80%;
    height: 11vh;
    box-sizing: border-box;
    margin: 2% 7%;
    padding: 0 20px;
    font-size: 1rem;
    border-radius: 5px;    
}
label{
    width:80%;
    height: 5vh;
    font-size: 1.3rem;
    margin: 0 7%;
    padding: 0 20px;
}
select{
    width:80vw;
    height: 11vh;
    box-sizing: border-box;
    margin: 2% 7%;
    padding: 0 20px;
    font-size: 1rem;
    border-radius: 5px;
}

.file{
    width:80%;
    height: 4vh;
    margin: 2%;
}
/* input#file-upload-button{
    width:80%;
    height: 8vh;
} */
p{
    width:80%;
    font-size: 1rem;
    margin: 2% 7%;
}
.submit{
    width:80%;
    height: 10vh;
    font-size: 1.2rem;
    font-weight: 550;
    border-radius: 5px; 
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
}