:root{
    /* Colores */
    --orange: #F7931A;
    --soft-orange: #FFE9D5;
    --warm-black: #201E1C;
    --warm-black1: #282623;
    --off-white:  #FAF8F7;
    --just-white: #FFFFFF;
    --grey: #bababa;
    --green: #103606;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.form{
    width: 100vw;
    height: 100vh;
    background-color: var(--just-white);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center; 
}
    .title_area{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center; 
        width: 100%;
        height: 25%;
        margin-top: 5%;
    }
        .title{
            width: 70%;
            height: 80%;
            font-size: 4.5rem;
            font-weight: 800;
            line-height: 5rem;
            margin-top: 0%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            color: var(--green);
        }
        .subtitle{
            width: 100%;
            height: 10%;
            font-size: 1.6rem;
            font-weight: 500;
            margin-top: 0%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            color: var(--grey);
        }
    .input_area{
        width: 100%;
        height: 45%;
        margin-top:5%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
    }
    
        .input{
            width: 70%;
            height: 25%;
            margin-top:2%;
            padding-left: 8%;
            font-size: 2rem;
            box-shadow: 0px 8px 6px rgba(89, 73, 30, 0.16);
            color: var(--warm-black);
            border: 2px solid var(--green);
            border-radius: 8px;
        }
    .error_area{
        width: 100%;
        height: 30%;
        display: flex;
        flex-wrap: wrap; 
        justify-content: center;
        align-items: flex-start;
    }
        .submit{
            width:70%;
            height: 35%;
            font-size: 2rem;
            font-weight: 550;
            border-radius: 5px; 
            margin-top: 0%;
            display: flex;
            flex-wrap: wrap; 
            justify-content: center;
            align-items: center;
            box-shadow: 0px 8px 6px rgba(89, 73, 30, 0.16);
            color: var(--warm-black);
            border: 2px solid var(--green);
            border-radius: 8px;
        }
        .alert{
            color:red;
        }