body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

form{
    width: 100vw;
    height: 100%;
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; 
}
h1{
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    justify-content: center;
    width:100%;
    height: 6vh;
    font-size: 1.3rem;
    margin: 2%;
}
input{
    width:80%;
    height: 11vh;
    box-sizing: border-box;
    margin: 2% 7%;
    padding: 0 20px;
    font-size: 1rem;
    border-radius: 5px;    
}
label{
    width:80%;
    height: 5vh;
    font-size: 1.3rem;
    margin: 0 7%;
    padding: 0 20px;
}
select{
    width:80vw;
    height: 11vh;
    box-sizing: border-box;
    margin: 2% 7%;
    padding: 0 20px;
    font-size: 1rem;
    border-radius: 5px;
}

.restautantUpload_file__ygDa2{
    width:80%;
    height: 4vh;
    margin: 2%;
}
/* input#file-upload-button{
    width:80%;
    height: 8vh;
} */
p{
    width:80%;
    font-size: 1rem;
    margin: 2% 7%;
}
.restautantUpload_submit__1KpLc{
    width:80%;
    height: 10vh;
    font-size: 1.2rem;
    font-weight: 550;
    border-radius: 5px; 
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
}
form{
    width: 100vw;
    height: 100vh;
    background-color: lightgray;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; 
}
h1{
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    justify-content: center;
    width:100%;
    height: 6vh;
    font-size: 1.5rem;
    margin: 2%;
}
input{
    width:80%;
    height: 11vh;
    display: flex;
    flex-wrap: wrap; 
    box-sizing: border-box;
    margin: 2% 7%;
    padding: 0 20px;
    font-size: 1rem;
    border-radius: 5px;    
}
label{
    width:80%;
    height: 5vh;
    font-size: 1.3rem;
    margin: 0 7%;
    padding: 0 20px;
}
select{
    width:80vw;
    height: 11vh;
    box-sizing: border-box;
    margin: 2% 7%;
    padding: 0 20px;
    font-size: 1rem;
    border-radius: 5px;
}

.platilloUpload_file__mSPBT{
    width:80%;
    height: 4vh;
    margin: 2%;
}
/* input#file-upload-button{
    width:80%;
    height: 8vh;
} */
p{
    width:80%;
    font-size: 1rem;
    margin: 2% 7%;
}
.platilloUpload_submit__1MIfY{
    width:80%;
    height: 10vh;
    font-size: 1.2rem;
    font-weight: 550;
    border-radius: 5px; 
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
}
form{
    width: 100vw;
    height: 100%;
    background-color: lightgray;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; 
}
h1{
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    justify-content: center;
    width:100%;
    height: 6vh;
    font-size: 2rem;
    margin: 2%;
}
input{
    width:80%;
    height: 11vh;
    box-sizing: border-box;
    margin: 2% 7%;
    padding: 0 20px;
    font-size: 1rem;
    border-radius: 5px;    
}
label{
    width:80%;
    height: 5vh;
    font-size: 1.3rem;
    margin: 0 7%;
    padding: 0 20px;
}
select{
    width:80vw;
    height: 11vh;
    box-sizing: border-box;
    margin: 2% 7%;
    padding: 0 20px;
    font-size: 1rem;
    border-radius: 5px;
}

.restDetailsUpload_file__w9eCI{
    width:80%;
    height: 4vh;
    margin: 2%;
}
/* input#file-upload-button{
    width:80%;
    height: 8vh;
} */
p{
    width:80%;
    font-size: 1rem;
    margin: 2% 7%;
}
.restDetailsUpload_submit__3qOBK{
    width:80%;
    height: 10vh;
    font-size: 1.2rem;
    font-weight: 550;
    border-radius: 5px; 
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
}
:root{
    /* Colores */
    --orange: #F7931A;
    --soft-orange: #FFE9D5;
    --warm-black: #201E1C;
    --warm-black1: #282623;
    --off-white:  #FAF8F7;
    --just-white: #FFFFFF;
    --grey: #bababa;
    --green: #103606;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.agregarCliente_form__vtwbC{
    width: 100vw;
    height: 100vh;
    background-color: #FFFFFF;
    background-color: var(--just-white);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center; 
}
    .agregarCliente_title_area__1ny37{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center; 
        width: 100%;
        height: 25%;
        margin-top: 5%;
    }
        .agregarCliente_title__1kO2F{
            width: 70%;
            height: 80%;
            font-size: 4.5rem;
            font-weight: 800;
            line-height: 5rem;
            margin-top: 0%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            color: #103606;
            color: var(--green);
        }
        .agregarCliente_subtitle__1elYd{
            width: 100%;
            height: 10%;
            font-size: 1.6rem;
            font-weight: 500;
            margin-top: 0%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            color: #bababa;
            color: var(--grey);
        }
    .agregarCliente_input_area__328n1{
        width: 100%;
        height: 45%;
        margin-top:5%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
    }
    
        .agregarCliente_input__2OuEq{
            width: 70%;
            height: 25%;
            margin-top:2%;
            padding-left: 8%;
            font-size: 2rem;
            box-shadow: 0px 8px 6px rgba(89, 73, 30, 0.16);
            color: #201E1C;
            color: var(--warm-black);
            border: 2px solid #103606;
            border: 2px solid var(--green);
            border-radius: 8px;
        }
    .agregarCliente_error_area__3f_Us{
        width: 100%;
        height: 30%;
        display: flex;
        flex-wrap: wrap; 
        justify-content: center;
        align-items: flex-start;
    }
        .agregarCliente_submit__1RHEK{
            width:70%;
            height: 35%;
            font-size: 2rem;
            font-weight: 550;
            border-radius: 5px; 
            margin-top: 0%;
            display: flex;
            flex-wrap: wrap; 
            justify-content: center;
            align-items: center;
            box-shadow: 0px 8px 6px rgba(89, 73, 30, 0.16);
            color: #201E1C;
            color: var(--warm-black);
            border: 2px solid #103606;
            border: 2px solid var(--green);
            border-radius: 8px;
        }
        .agregarCliente_alert__2T2lS{
            color:red;
        }
:root{
    /* Colores */
    --orange: #F7931A;
    --soft-orange: #FFE9D5;
    --warm-black: #201E1C;
    --warm-black1: #282623;
    --off-white:  #FAF8F7;
    --just-white: #FFFFFF;
    --grey: #bababa;
    --green: #103606;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html{
    font-size: 62.5%;
}
.signin_allwrapper__36h6g{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    height: 100vh;    
    background-color: #FFFFFF;    
    background-color: var(--just-white)
}

.signin_agregarclientes__1dMD7{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    width: 100%;
    height: 7%;
    padding-right: 10%;
    font-size: 2rem;
    color: black;
    text-decoration: none;
}
   
    .signin_aqui__2ri7m{
        width: 9%;
        color: blue;
    }
form{
    background-color: #FFFFFF;
    background-color: var(--just-white); 
}
.signin_form__-Opfx{
    width: 100%;
    height: 93%;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;    
}
    .signin_title__o5XoP{
        width: 60%;
        height: 8%;
        font-size: 4.5rem;
        font-weight: 800;
        margin-top: 0%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        color: #103606;
        color: var(--green);
    }
    .signin_respuesta_servidor__3VrLv{
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        width: 100%;
        height: 4%;
        margin-top: 0;
        font-size: 2rem;
        font-weight: 500;
        color: red;
    }
    .signin_input__2xRPp{
        width: 70%;
        height: 12%;
        margin-top:2%;
        padding-left: 8%;
        font-size: 2rem;
        box-shadow: 0px 8px 6px rgba(89, 73, 30, 0.16);
        color: #201E1C;
        color: var(--warm-black);
        border: 2px solid #103606;
        border: 2px solid var(--green);
        border-radius: 8px;
    }
    .signin_select__36K2a{
        width: 70%;
        height: 12%;
        margin-top:5%;
        padding-left: 8%;
        font-size: 2rem;
        box-shadow: 0px 8px 6px rgba(89, 73, 30, 0.16);
        color: #201E1C;
        color: var(--warm-black);
        border: 2px solid #103606;
        border: 2px solid var(--green);
        border-radius: 8px;
    }
    .signin_submit__1NyBI{
        display: flex;
        justify-content: center;
        width: 70%;
        height: 12%;
        margin-top:5%;;
        font-size: 3.3rem;
        font-weight: 500;
        border-radius: 10px;
        box-shadow: 0px 8px 6px rgba(89, 73, 30, 0.16);
        color: #201E1C;
        color: var(--warm-black);
        border: 3px solid #103606;
        border: 3px solid var(--green);
    }
    .signin_input_data_h2__2neaG{
        font-size: 1.7rem;
        font-weight: 400;
    }
    
        
@media only screen and (min-width: 820px) {
    .signin_agregarclientes__1dMD7{
        width: 100%;
        height: 5%;
        padding-right: 27%;
    }
        .signin_aqui__2ri7m{
            width: 6%;
        }
    .signin_form__-Opfx{
        width:65%;
    }
    .signin_title__o5XoP{
        font-size: 6.2rem;
        margin-top: 1%;
        font-weight: 800;
    }
    .signin_input__2xRPp{
        margin-top:0%;
    }
    .signin_select__36K2a{
        margin-top:0%;
    }
    .signin_submit__1NyBI{
        margin-top:0%;
    }
}
:root{
    /* Colores */
    --orange: #F7931A;
    --soft-orange: #FFE9D5;
    --warm-black: #201E1C;
    --warm-black1: #282623;
    --off-white:  #FAF8F7;
    --just-white: #FFFFFF;
    --grey: #bababa;
    --green: #103606;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html{
    font-size: 62.5%;
    font-family:'DM Sans', sans-serif;
}
.navBar_navBar__1HpeI{
    width:100vw;
    height: 12vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color:#103606;
    color:var(--green);
}
    .navBar_link_local__3G1GM{
        text-decoration: none;
    }
    .navBar_menu_button__3aXYg{
        width: 14vh;
        height: 12vh;
        font-size: 1.3rem;
        font-weight: 700;
        border-radius: 50%;
        margin: 0 1.5vw;
        border: solid;
        border-color: gainsboro;
        border-width: 2px;
        box-shadow: 0px 2px 8px gainsboro;
        color: #103606;
        color: var(--green);
        background-color: #FFFFFF;
        background-color: var(--just-white);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    }
    .navBar_menu_button__3aXYg:hover{
        background-color: gainsboro;
        color: white
    }
    
    
@media only screen and (min-width: 390px) {
    .navBar_navBar__1HpeI{
        height: 13vh;
    }
    .navBar_menu_button__3aXYg{
        width: 14vh;
        height: 13vh;
        margin: 0 1vw;
        font-size: 1.6rem;
    }
}

@media only screen and (min-width: 760px) {
    .navBar_navBar__1HpeI{
        height: 14vh;
    }
    .navBar_menu_button__3aXYg{
        width: 14vh;
        height: 14vh;
        margin: 0 5vw;
        font-size: 1.8rem;
        font-weight: 800;
    }
}

@media only screen and (min-width: 1100px) {
    .navBar_navBar__1HpeI{
        width:100vw;
        height: 26vh;
    }
    .navBar_menu_button__3aXYg{
        width: 26vh;
        height: 26vh;
        margin: 0 3vw;
        font-size: 1.8rem;
    }
}
:root{
    /* Colores */
    --orange: #F7931A;
    --soft-orange: #FFE9D5;
    --warm-orange: #dfa48d;
    --warm-black: #201E1C;
    --warm-black1: #282623;
    --off-white:  #FAF8F7;
    --just-white: #FFFFFF;
    --grey: #bababa;
    --green: #103606;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html{
    font-size: 62.5%;
}
.Homemobile_all__2jIp7{
    background-color: #FFFFFF;
    background-color: var(--just-white);
    color: #103606;
}
    .Homemobile_header_title__3m5qj{
        font-size: 3rem;
        font-weight: 800;
        height: 15%;
        margin: 1.5vh 0vh;
        
    }
    .Homemobile_header_subtitle__2kf3h{
        font-size: 3rem;
        font-weight: 700;
    }

    .Homemobile_title_proceso__sRT48{
        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content: flex-start;
        width: 100%;
        height: 5vh;
        margin-left: 3%;
        margin-top: 1%;
        padding: 0;
        font-size: 2.5rem;
        font-weight: 700; 
    }
    
    .Homemobile_pedido_proceso_section__1208j{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        padding-left: 1%;
        font-size: 2.2rem;
        font-weight: 600;
        margin-bottom: 2vh;
        background-color: #FFE9D5;
        background-color: var(--soft-orange);
        box-shadow: 0px 15px 6px gainsboro;
    }
    .Homemobile_proceso_sub__1Zasl{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 12vh;
    }
        .Homemobile_proceso_sub_left__2nSCw{
            display: flex;
            flex-wrap: wrap;
            width: 25%;
            height: 12vh;
        }
            .Homemobile_id_title__3LRW5{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                margin-left: 25%;
                width: 25%;
                height: 6vh;
                font-weight: 800;
            }
            .Homemobile_id__2htUp{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: 25%;
                height: 6vh;
            }
            .Homemobile_cantidad_title__2Txd0{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 3vh;
                font-weight: 800;
            }
            .Homemobile_cantidad__flU-k{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 3vh;
            }
              
        .Homemobile_proceso_sub_right__t8lAQ{
            display: flex;
            flex-wrap: wrap;
            width: 75%;
            height: 12vh;
            margin:0;
        }
            .Homemobile_platillo_title__x9KZX{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;
                height: 3vh;
                font-weight: 800;
            }
            .Homemobile_platillo__UbCQy{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                width: 100%;
                height: 3vh;
            }
    
            .Homemobile_notas_title__IAVAt{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:flex-start;
                width: 100%;
                font-weight: 800;
                height: 3vh;
            }
            .Homemobile_notas__fd69s{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:flex-start;
                width: 100%;
                height: 3vh;
                margin: 0;
                padding: 0;
            }

    .Homemobile_reparto__2jb4a{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 100%;
            height: auto;
            margin-top: 1vh;
    }
        
        .Homemobile_boton_mobile__7pVoj{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 100%;
            height: 6vh;
            margin-left:-1vw;
            border: 0;
            font-size: 2.8rem;
            font-weight: 700;
            background-color: white;
            color: black;
        }
        .Homemobile_boton_mobile__7pVoj:hover{
            background-color: black;
            color: white;
        }
        .Homemobile_reparto_e___7Yh8{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 100%;
            height: 5vh;
            color: #dfa48d;
            color: var(--warm-orange);
        }
            .Homemobile_enviowrap__FDvzF{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                width:31%;
            }
                
                /* .boton_e{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items:center;
                    width: 30%;
                    margin-left: 17%;
                    border-radius: 10px;
                    background-color: white;
                    color: black;
                }
                .boton_e:hover{
                    background-color: black;
                    color: white;
                } */
            


.navBarRepartidor_navBar__4_Bm5{
    width:100vw;
    height: 35vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
    .navBarRepartidor_bienvenido__2YAff{
        width: 100vw;
        height: 8vh;
        margin: 2vh 0;
        font-size: 2.2rem;
        font-weight: 700;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
    }
    .navBarRepartidor_menu_button__3boYQ{
        width: 18vh;
        height: 18vh;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 50%;
        margin: 0 2.5vw;
        border: solid;
        border-color: gainsboro;
        border-width: 2px;
        box-shadow: 0px 2px 8px gainsboro;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: white;
    }
    .navBarRepartidor_menu_button__3boYQ:hover{
        background-color: gainsboro;
        color: white
    }
    
    

.HomeRep_proceso__wyBQE{
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: flex-start;
    width: 100%;
    height: 5vh;
    margin-left: 3%;
    margin-top: 1%;
    padding: 0;
    font-size: 1rem;
    font-weight: 500;
    background-color:beige;
}

.HomeRep_pedido__dTw_n{
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;
    width: 100vw;
    height: 10vh;
    margin: 2vh 0;
    font-weight: 700;
    font-size: 1.3rem;
}

.HomeRep_titulowrap__2mag7{
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;
    width: 83%;
    height: 5vh;
    padding-left: 12%;
    font-size: 1rem;
    font-weight: 700;
}

.HomeRep_pedidowrap__3MV8I{
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;
    width: 83%;
    height: 5vh;
    padding-left: 12%;
    font-size: 1rem;
    font-weight: 400;
}
        .HomeRep_cantidad__32ufG{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 6%;
            margin-left: 1.5%;
        }
        .HomeRep_platillo__2zUPF{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 23%;
        }
        .HomeRep_notas__1nTJB{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 22%;
        }
        .HomeRep_reparto__2s6uQ{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 16%;
        }
        
        .HomeRep_boton__OrHkB{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 12vw;
            margin-left: 5%;
            border-radius: 10px;
            background-color: white;
            color: black;
        }
        .HomeRep_boton__OrHkB:hover{
            background-color: black;
            color: white;
        }
        .HomeRep_enviowrap__3siRH{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width:31%;
        }
            .HomeRep_reparto_e__2vnHh{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                width: 50%;
            }
            .HomeRep_boton_e__3uy7x{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                width: 30%;
                margin-left: 17%;
                border-radius: 10px;
                background-color: white;
                color: black;
            }
            .HomeRep_boton_e__3uy7x:hover{
                background-color: black;
                color: white;
            }
            
:root{
    /* Colores */
    --orange: #F7931A;
    --soft-orange: #FFE9D5;
    --warm-black: #201E1C;
    --warm-black1: #282623;
    --off-white:  #FAF8F7;
    --just-white: #FFFFFF;
    --grey: #bababa;
    --green: #103606;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html{
    font-size: 62.5%;
}
.Home_all__s37Ql{
    background-color: #FFFFFF;
    background-color: var(--just-white);
    color: #103606;
}
    .Home_header_title__1yjtQ{
        font-size: 5.5rem;
        font-weight: 800;
        height: 15%;
        margin: 1.5vh 0vh;
        
    }
    .Home_header_subtitle__1IJ5L{
        font-size: 5.5rem;
        font-weight: 700;
    }

    .Home_grid_header__3keP2{
        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content: center;
        width: 100%;
        height: 6vh;
        padding-left: 12%;
        margin-bottom: -1%;
        font-size: 2.3rem;
        font-weight: 700;
        background-color:beige;
    }
        .Home_cantidad__2RPII{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 10%;
            margin-left: 1%;
        }
        .Home_platillo__3ZhIE{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 25vw;
        }
        .Home_notas__3SPTZ{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 22%;
        }
        .Home_reparto__QHMlA{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 16%;
        }
        
        .Home_boton_proceso__1gh3Y{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 9%;
            height: 6vh;
            margin-left: 2%;
            border-radius: 10px;
            font-size: 2rem;
            font-weight: 700;
            background-color: white;
            color: black;
        }
        .Home_boton_proceso__1gh3Y:hover{
            background-color: black;
            color: white;
        }
    .Home_title_proceso__2obC7{
        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content: flex-start;
        width: 100%;
        height: 5vh;
        margin-left: 0;
        margin-top: 1%;
        padding-left: 3%;
        font-size: 2rem;
        font-weight: 700; 
        width: 100%;
        background-color:beige;
    }

    .Home_borrar__KKZjH{
        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content: center;
        width: 100%;
        height: 5vh;
        padding-left: 12%;
        font-size: 2.3rem;
        font-weight: 700;
        background-color: #F7931A;
        background-color: var(--orange);
    }
    .Home_pedido_proceso_section__3LuXz{
        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content: center;
        width: 100%;
        height: 8.5vh;
        margin: 0;
        padding-left: 12%;
        font-size: 1.7rem;
        font-weight: 700;
        background-color: #FFFFFF;
        background-color: var(--just-white);
        box-shadow: 0px 15px 6px gainsboro;
        background-color:#F7931A;
        background-color:var(--orange) ;
    }
            .Home_enviowrap__1AG7X{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                width:10%;
            }
                .Home_reparto_e__3r0s3{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items:center;
                    width: 50%;
                }
                .Home_boton_e__3rT5c{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items:center;
                    font-size: 2rem;
                    font-weight: 700;
                    width: 100%;
                    height: 6vh;
                    margin-left: 17%;
                    border-radius: 10px;
                    background-color: white;
                    color: black;
                }
                .Home_boton_e__3rT5c:hover{
                    background-color: black;
                    color: white;
                }
            
/* @media only screen and (min-width: 1000px) {
 
        .platillo{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 23%;
        }
        .notas{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 22%;
        }
        .reparto{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;
            width: 16%;
        }
        
        
        .boton_e:hover{
            background-color: black;
            color: white;
        }
            .reparto_e{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                width: 50%;
            }
            

} */
