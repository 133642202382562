
.navBar{
    width:100vw;
    height: 35vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
    .bienvenido{
        width: 100vw;
        height: 8vh;
        margin: 2vh 0;
        font-size: 2.2rem;
        font-weight: 700;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
    }
    .menu_button{
        width: 18vh;
        height: 18vh;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 50%;
        margin: 0 2.5vw;
        border: solid;
        border-color: gainsboro;
        border-width: 2px;
        box-shadow: 0px 2px 8px gainsboro;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: white;
    }
    .menu_button:hover{
        background-color: gainsboro;
        color: white
    }
    
    